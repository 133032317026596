<template>
  <div class="from_con user-input-info-form">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="70px"
      class="demo-ruleForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="姓名"
            prop="name"
          >
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入您的姓名"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="职务">
            <el-input
              v-model="ruleForm.duty"
              placeholder="请输入您的职务"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        class="mt-20"
        :gutter="20"
      >
        <el-col :span="12">
          <el-form-item
            label="电话"
            prop="phone"
          >
            <el-input
              v-model="ruleForm.phone"
              placeholder="请输入联系电话"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱">
            <el-input
              v-model="ruleForm.email"
              placeholder="请输入邮箱地址"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        class="mt-20 el_li"
        :gutter="20"
      >
        <el-col :span="24">
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="地址"
                prop="provinceValue"
              >
                <el-select
                  v-model="ruleForm.provinceValue"
                  placeholder="省份"
                  @change="provinceChange"
                >
                  <el-option
                    v-for="(item,index) in province"
                    :key="index"
                    :label="item.label"
                    style="padding: 0 20px"
                    :value="index"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="text-center"
                prop="cityValue"
              >
                <el-select
                  v-model="ruleForm.cityValue"
                  :disabled="cityStatus"
                  placeholder="城市"
                  @change="cityChange"
                >
                  <el-option
                    v-for="(item,index) in city"
                    :key="index"
                    :label="item.label"
                    style="padding: 0 20px"
                    :value="index"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="countyValue">
                <el-select
                  v-model="ruleForm.countyValue"
                  :disabled="countyStatus"
                  placeholder="区县"
                  @change="countyChange"
                >
                  <el-option
                    v-for="(item,index) in county"
                    :key="index"
                    :label="item.label"
                    style="padding: 0 20px"
                    :value="index"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row
        class="mt-20"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item label="公司">
            <el-input
              v-model="ruleForm.company"
              placeholder="请输入公司名称"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        class="mt-20"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item label="其他">
            <el-input
              v-model="ruleForm.more"
              type="textarea"
              :rows="5"
              placeholder="其他您想要告诉我们的"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div
        class="buttonShen"
        @click="submitForm('ruleForm')"
      >
        立即体验
      </div>
    </el-form>
  </div>
</template>

<script>
import areac from '../assets/js/areac';

export default {
  name: 'InfoForm',
  data() {
    return {
      prohibit: true,
      province: [],
      provinceIndex: null, // 省的下标
      city: [],
      cityIndex: null, // 城市的下标
      cityStatus: true,
      county: [],
      countyStatus: true,
      countyIndex: null, // 区县的下标
      /** ******************表单的验证************** */
      ruleForm: {
        name: '',
        duty: '',
        phone: '',
        email: '',
        company: '',
        more: '',
        provinceValue: '',
        provinceCode: '',
        cityValue: '',
        cityCode: '',
        countyValue: '',
        address: '',
        areaCode: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入您的联系电话', trigger: 'blur' },
        ],
        provinceValue: [
          { required: true, message: '请选择您的省份', trigger: 'change' },
        ],
        cityValue: [
          { required: true, message: '请选择您的城市', trigger: 'change' },
        ],
        countyValue: [
          { required: true, message: '请选择您的区县', trigger: 'change' },
        ],
      },
    };
  },
  created() {
    this.getCity();
  },
  methods: {
    // 点击选中县城
    countyChange(val) {
      this.ruleForm.countyValue = this.county[val].label;
      this.ruleForm.areaCode = this.county[val].value;
    },
    // 点击选中市
    cityChange(val) {
      this.cityIndex = val;
      this.ruleForm.countyValue = '';
      this.ruleForm.areaCode = '';
      this.county = this.province[this.provinceIndex].children[val].children;
      this.countyStatus = false;
      this.ruleForm.cityValue = this.city[val].label;
      this.ruleForm.cityCode = this.city[val].value;
    },
    // 点击选中省
    provinceChange(val) {
      this.provinceIndex = val;
      this.county = '';
      this.ruleForm.cityValue = '';
      this.ruleForm.countyValue = '';
      this.ruleForm.cityCode = '';
      this.ruleForm.areaCode = '';
      this.city = this.province[val].children;
      this.cityStatus = false;
      this.ruleForm.provinceValue = this.province[val].label;
      this.ruleForm.provinceCode = this.province[val].value;
    },
    // 获取三级联查的各个地区
    getCity() {
      this.province = areac.data.filter(item => [
        item.value,
        item.label,
      ]);
    },
    // 马上加入
    submitForm(formName) {
      const self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (self.prohibit) {
            self.prohibit = false;
            self.$ajax({
              url: '/je/product/crm/customer/saveSaleLead',
              data: {
                typeCode: 'jepaas',
                name: self.ruleForm.name,
                telePhone: self.ruleForm.phone,
                corporateName: self.ruleForm.company,
                provinceName: self.ruleForm.provinceValue,
                provinceCode: self.ruleForm.provinceCode,
                cityName: self.ruleForm.cityValue,
                cityCode: self.ruleForm.cityCode,
                areaName: self.ruleForm.countyValue,
                areaCode: self.ruleForm.areaCode,
                duties: self.ruleForm.duty,
                email: self.ruleForm.email,
                describe: self.ruleForm.more,
              },
            }).then(() => {
              self.prohibit = true;
              this.$message.success('提交成功请静候佳音!');
              self.ruleForm.name = '';
              self.ruleForm.phone = '';
              self.ruleForm.company = '';
              self.ruleForm.provinceValue = '';
              self.ruleForm.provinceCode = '';
              self.ruleForm.cityValue = '';
              self.ruleForm.cityCode = '';
              self.ruleForm.countyValue = '';
              self.ruleForm.areaCode = '';
              self.ruleForm.duty = '';
              self.ruleForm.email = '';
              self.ruleForm.more = '';
              this.$refs[formName].resetFields();
            }).catch(() => {

            });
          }
        } else {
          this.$message.error('请将所有必填信息填写完整');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less">
  .user-input-info-form {
    width: 950px;
    height: 680px;
    margin: 60px auto;
    padding: 100px 90px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: left;
    box-shadow:0px 5px 20px 0px rgba(0, 0, 0, 0.1);
    .mt-20 {
      margin-top: 20px;
    }
    .buttonShen {
      cursor: pointer;
      width: 480px;
      margin: 90px auto;
      height: 60px;
      background: rgba(255, 48, 65, 1);
      box-shadow: 2px 2px 10px 0px rgba(252, 44, 54, 0.4);
      border-radius: 30px;
      line-height: 60px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(254, 255, 255, 1);
      text-align: center;
      position: relative;
    }
    .buttonShen::after {
      content: "";
      width: 480px;
      height: 60px;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 30px;
      z-index: -1;
      transition: background 0.7s;
    }
    .buttonShen:hover:after {
      z-index: 2;
      background: rgba(51, 51, 51, 0.1);
    }
    // .el-form-item {
    //   margin-bottom: 0;
    // }
  }
</style>
