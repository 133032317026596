<template>
  <div class="page_box">
    <div class="box_top">
      <Header class="page_hea" />
    </div>
    <div class="page__content">
      <div>
        <div class="section imgs">
          <div class="content">
            <p class="title">
              JEPaaS客户应用成功案例
            </p>
            <p class="subtitle">
              深耕行业十多年，JEPaaS在金融、通信、能源、教育等多个产业领域拥有上千家企业的数字化成功实践。
            </p>
            <div class="jump-imgs">
              <div
                v-for="(item, index) in imgList"
                :key="index"
                @click.stop.prevent="jump(item, index)"
              >
                <img
                  :src="item.img"
                  :alt="item.text"
                >
                <p>{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="section client">
          <div class="content">
            <p class="title">
              客户评价
            </p>
            <div
              v-for="(item, index) in clientList"
              :key="index"
              :class="`client-card ${ (index + 1) % 2 == 0 ? 'right': '' }`"
            >
              <img
                :src="item.img"
                :alt="item.title"
              >
              <div>
                <p class="name">
                  {{ item.title }}
                </p>
                <p class="text">
                  {{ item.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="section company">
          <div class="content">
            <p class="title">
              JEPaaS，让企业数字化大有作为
            </p>
            <div class="company">
              <img
                v-for="item in companyList"
                :key="item"
                :src="item"
                alt=""
              >
            </div>
          </div>
        </div>
        <div class="section form">
          <div class="content">
            <p class="title">
              与JEPaaS一起开创数字化创新未来
            </p>
            <info-form />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script >
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import infoForm from '@/components/infoForm.vue';

// eslint-disable-next-line camelcase
import bg_su from '@/assets/imgs/successful/bg_su.png';
import jump01 from '@/assets/imgs/successful/from20200103/list-1.png';
import jump02 from '@/assets/imgs/successful/from20200103/list-2.png';
import jump03 from '@/assets/imgs/successful/from20200103/list-3.png';
import jump04 from '@/assets/imgs/successful/from20200103/list-4.png';
import jump05 from '@/assets/imgs/successful/from20200103/list-5.png';
import jump06 from '@/assets/imgs/successful/from20200103/list-6.png';
import client01 from '@/assets/imgs/successful/from20200103/client-zhuchangxiong.png';
import client02 from '@/assets/imgs/successful/from20200103/client-jiabiao.png';
import client03 from '@/assets/imgs/successful/from20200103/client-tanyunjian.png';
import client04 from '@/assets/imgs/successful/from20200103/client-wangwenhua.png';
import company01 from '@/assets/imgs/successful/client01.png';
import company02 from '@/assets/imgs/successful/client02.png';
import company03 from '@/assets/imgs/successful/client03.png';
import company04 from '@/assets/imgs/successful/client04.png';
import company05 from '@/assets/imgs/successful/client05.png';
import company06 from '@/assets/imgs/successful/client06.png';
import company07 from '@/assets/imgs/successful/client09.png';
import company08 from '@/assets/imgs/successful/client08.png';

export default {
  name: 'SuccessfulPage',
  components: {
    Header,
    Footer,
    infoForm,
  },
  data() {
    return {
      // eslint-disable-next-line camelcase
      bg_su,
      imgList: [
        {
          img: jump01,
          text: '大型金融企业内部办公管理系统',
        },
        {
          img: jump02,
          text: '长沙某集团采购管理系统',
        },
        {
          img: jump03,
          text: '集团型能源企业设备管理系统',
        },
        {
          img: jump04,
          text: '蒜瓣SCRM客户关系管理系统',
        },
        {
          img: jump05,
          text: '高校数字校园系统应用',
        },
        {
          img: jump06,
          text: '集团型制造企业信息管理工作系统',
        },
      ],
      clientList: [
        {
          img: client01,
          title: '大型金融企业机构委架构师 朱常雄',
          text: 'JEPaaS平台为提高管理类系统的开发效率而生!目前我们的系统有1000多个功能，至少80%的功能是通过JEPaaS直接配置出来的，高效的图表引擎和工作流引擎，对我们项目的提供了极大的便利;同时JEPaaS还支持自定义开发， 平台自带了大量易懂易用的API,可根据业务需求来自定义页面的开发，并能很方便的集成到平台中来。',
        },
        {
          img: client02,
          title: '某信息技术(北京)有限公司执行副总裁 贾彪',
          text: 'JEPaaS是我司使用的第一个快速开发平台。我司使用JEPaaS开发并上线了 “队伍建设管理系统”、'
          + '"综合设备管理系统"等系统。项目成功上线后，通过对比原纯代码的开发模式，我司一致认为JEPaaS是最好、最适合的快速'
          + '开发平台，特别是对数据库的操作，可以快速对数据表的字段、类型进行修改并能及时的呈现到页面和数据录入上，还有后台的'
          + '动态bean省下了大量写实体类的时间，可以快速的获取前台传到后台的数据。可以说，JEPaaS是初创公司及软件工坊的首选平台，'
          + '第一节约人工成本，第二开发周期短、成品见效快、平台稳定成熟。',
        },
        {
          img: client03,
          title: '某设计研究院负责人 谈云键',
          text: 'JEPaaS作为一个成熟的软件快速开发平台，支持mysql和oracle，可以在短时间内以低成本快速完成一个项目。在平台的使用中，许多非常有用又有趣的功能逐渐被发掘，比如软件打包升级功能，使上线成本得以大幅度减轻;还有JEPaaS手机APP开发的功能，开发一次，安卓和ios都可使用，确实值得点赞!',
        },
        {
          img: client04,
          title: '某科技有限公司负责人 王文华',
          text: '我们用JEPaaS开发了一个站式服务平台。多亏有了JEPaaS，我们才能这么快就搭建了属于自己的平台。不需要太多专业知识，只需跟着视频教程就能上手开发，扩展性强，功能也多，最重要的是还有自带的APP，完全可能实现移动办公，太方便了!',
        },
      ],
      companyList: [company01, company02, company03, company04, company05, company06, company07, company08],
    };
  },
  methods: {
    jump(item, index) {
      this.$router.push({
        name: 'successfulDetailPage',
        query: { index },
      });
    },
  },
};
</script>

<style lang='less' scoped >
.page_box {
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .page__content {
    p {
      margin: 0;
      padding: 0;
    }
    .section {
      text-align: center;
      > .content {
        padding: 120px 0 100px;
        width: 1200px;
        margin: 0 auto;
        > .title {
          color: #000000;
          font-size: 40px;
          font-weight: bold;
        }
        > .subtitle {
          color: #333333;
          font-size: 16px;
          margin-top: 30px;
        }
      }
      &.imgs {
        .jump-imgs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 30px;
          > div {
            width: 585px;
            height: 400px;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            margin-top: 50px;
            cursor: pointer;
            > img {
              width: 100%;
              height: 100%;
              transition: 300ms all;
            }
            > p {
              background: rgba(0, 0, 0, .5);
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              height: 80px;
              line-height: 80px;
              color: #ffffff;
            }
            &:hover {
              > img {
                transform: scale(1.1);
              }
            }
          }
        }
      }
      &.client {
        background: #ECF3F9;
        .client-card {
          background:rgba(255,255,255,1);
          box-shadow:0px 10px 30px 0px rgba(0, 0, 0, 0.1);
          border-radius: 120px;
          width: 100%;
          height: 240px;
          position: relative;
          margin-top: 50px;
          padding: 0 105px 0 245px;
          text-align: left;
          box-sizing: border-box;
          &:first-child {
            margin-top: 80px;
          }
          > img {
            width: 130px;
            height: 130px;
            border-radius: 100%;
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 55px;
            transform: translate(0, -50%);
          }
          > div {
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
            > .name {
              color: #333333;
              font-size: 24px;
              font-weight: bold;
            }
            > .text {
              font-size: 16px;
              color: #666666;
              margin-top: 25px;
            }
          }
          &.right {
            text-align: right;
            padding: 0 245px 0 105px;
            > img {
              top: 50%;
              right: 55px;
              left: auto;
              transform: translate(0, -50%);
            }
          }
        }
      }
      &.company {
        .company {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 40px;
          > img {
            margin-top: 40px;
            width: 272.8px;
            height: 182px;
            box-shadow:0px 5px 15px 0px rgba(0, 0, 0, 0.2);
            border-radius:4px;
          }
        }
      }
      &.form {
        background:linear-gradient(0deg,rgba(255,255,255,1) 0%,rgba(255,239,235,1) 100%);
      }
    }
  }
}
</style>
